"use client";

import { setSelectedAccountPage } from "@/redux/slices/accountSlice";
import { usePathname, useRouter } from "next/navigation";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

export default function Footer() {
  const dispatch = useDispatch();
  const { pages } = useSelector((state) => state.accountPages || []);
  const { SelectedAccountPage } = useSelector(
    (state) => state.accountPages || null
  );
  const router = useRouter();
  const pathName = usePathname();

  const handlePageTitleClick = (page) => {
    if (page === SelectedAccountPage) return;
    dispatch(setSelectedAccountPage(page));
    router.push(`/account`);
  };

  if (pathName === "/account") return;
  return (
    pages?.length > 0 && (
      <div className="fixed bottom-0 w-full h-[5%] bg-gray-100 shadow-xl py-2 flex gap-5 px-4 z-50">
        {pages?.map((page, index) => (
          <div
            className="cursor-pointer"
            key={index}
            onClick={() => handlePageTitleClick(page)}
          >
            <p>{page.page_name}</p>
          </div>
        ))}
      </div>
    )
  );
}
