"use client";

import { useEffect, useState } from "react";
import { getSubdomain } from "@/Utils/commons";

const DynamicTitle = () => {
  const [subDomain, setSubDomain] = useState(null);

  useEffect(() => {
    const domain = getSubdomain();
    setSubDomain(domain);
  }, []);

  const title = subDomain ? `${subDomain.toUpperCase()} - Menu` : "E - Menu";

  return (
    <head>
      <title>{title}</title>
    </head>
  );
};

export default DynamicTitle;
